
// import { defaultQueryFn } from '../../../queryapi';

import {
	globalStore,
	// setAboutParagraphs,
	setCurrentAbout,
	setAdminCurrAbout,
	setAdminModeAddMod,

	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_na,
	setFAdmAbo_en,
	setFAdmAbo_de,
	setFAdmAbo_tm,
	setFAdmAbo_dm,
} from '../../../globalstate';

import {
	StyAboutTitleForceH1,
	StyAboutTitleForceH2,
	StyAboutTitleForceH3,
	StyAboutTitleForceH4,
	StyAboutTitleForceH5,
	StyAboutTitleForceH6,
	// StyAboutTitleForceH7,
} from '../../../style/';

function About_ctrl(forCtrler) {

	const getAbout = () => {
		let retAbout = forCtrler.initAbout;
		if (forCtrler.location !== 'ADMIN') {
			// console.log(forCtrler.aboutAll)
			if (forCtrler.aboutAll !== undefined && forCtrler.aboutAll.length !== 0) {
				forCtrler.aboutAll.forEach((about) => {
					if (about.slug.toString() === forCtrler.slugAbout.toString()) {
						if (about.title_force === 1) {
							retAbout = about;
							globalStore.dispatch(setCurrentAbout(about));
						}
					}
				});
			}
		}
		return retAbout;
	};

	const onClickLocalAdmin = (event) => {
	    let para = {};

		// forCtrler.about_text.forEach((aboutPara) => {
		forCtrler.aboutAll.forEach((aboutPara) => {
			if(aboutPara.id.toString() === event.target.getAttribute("value").toString()) {
				para = aboutPara;
	            return aboutPara;
	        }
		});

		if (forCtrler.location === 'ADMIN') {
			globalStore.dispatch(setAdminCurrAbout(para));
			globalStore.dispatch(setAdminModeAddMod("M"));

			globalStore.dispatch(setFAdmAbo_ti(para.title));
			globalStore.dispatch(setFAdmAbo_tf(para.title_force));
			globalStore.dispatch(setFAdmAbo_co(para.content));
			globalStore.dispatch(setFAdmAbo_ip(para.img_path));
			globalStore.dispatch(setFAdmAbo_op(para.order_place));
			globalStore.dispatch(setFAdmAbo_na(para.no_article));
			globalStore.dispatch(setFAdmAbo_en(para.enable));
			globalStore.dispatch(setFAdmAbo_de(para.is_delete));
		
			globalStore.dispatch(setFAdmAbo_tm(para.chron_title_meta));
			globalStore.dispatch(setFAdmAbo_dm(para.chron_descr_meta));
		}
	};

	const putTitleForce = (id, title_force, title, onClickAdmin, location) => {
		
		let funcAction = () => {return '';};

		if (onClickAdmin === true) {
			funcAction = onClickLocalAdmin;
		}

		switch(title_force) {
			case 1 :
				return (<StyAboutTitleForceH1 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH1>)
			case 2 :
				return (<StyAboutTitleForceH2 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH2>)
			case 3 :
				return (<StyAboutTitleForceH3 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH3>)
			case 4 :
				return (<StyAboutTitleForceH4 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH4>)
			case 5 :
				return (<StyAboutTitleForceH5 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH5>)
			case 6 :
				return (<StyAboutTitleForceH6 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH6>)
			default:
				return (<StyAboutTitleForceH1 location={location} onClick={funcAction} value={id}>{title}</StyAboutTitleForceH1>)
		};
	};

	const onClickMenuleftAbout = (event) => {
		const linkAbout = '/chronique/' + event.target.id; // = about.slug
		forCtrler.navigate(linkAbout);
	};

	return {
		getAbout: getAbout,
		putTitleForce: putTitleForce,
		onClickMenuleftAbout: onClickMenuleftAbout,
	};
} 

export default About_ctrl;