
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setAdminInfos,
	setFAdmInf_linkIg,
	setFAdmInf_linkFb,
	setFAdmInf_linkTt,
	setFAdmInf_linkPt,
	setFAdmInf_myEmail,
	setFAdmInf_shopName,
	setFAdmInf_shopSt1,
	setFAdmInf_shopSt2,
	setFAdmInf_shopCp,
	setFAdmInf_shopCt,
	// setFAdmInf_shopCo,
	setFAdmInf_shopPh,
	setFAdmInf_shopMa,
	setFAdmInf_shopSi,
	setFAdmInf_pc_tm,
	setFAdmInf_pc_tp,
	setFAdmInf_pc_dm,
	setFAdmInf_pc_dp,
	setFAdmInf_pa_tm,
	setFAdmInf_pa_tp,
	setFAdmInf_pa_dm,
	setFAdmInf_pa_dp,
	setFAdmInf_ph_tm,
	setFAdmInf_ph_tp,
	setFAdmInf_ph_dm,
	setFAdmInf_ph_dp,
	setFAdmInf_s_a_cn,
} from '../../../../globalstate';

function AdminManageInfo_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_linkIg === true || forCtrler.adminInfoNew_linkIg === "") {
			if (forCtrler.adminInfoNew_linkIg === "" && fromBtSave) {
				forCtrler.setErrorEpty_linkIg(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_linkFb === true || forCtrler.adminInfoNew_linkFb === "") {
			if (forCtrler.adminInfoNew_linkFb === "" && fromBtSave) {
				forCtrler.setErrorEpty_linkFb(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_linkTt === true || forCtrler.adminInfoNew_linkTt === "") {
			if (forCtrler.adminInfoNew_linkTt === "" && fromBtSave) {
				forCtrler.setErrorEpty_linkTt(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_linkPt === true || forCtrler.adminInfoNew_linkPt === "") {
			if (forCtrler.adminInfoNew_linkPt === "" && fromBtSave) {
				forCtrler.setErrorEpty_linkPt(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_myEmail === true || forCtrler.adminInfoNew_myEmail === "") {
			if (forCtrler.adminInfoNew_myEmail === "" && fromBtSave) {
				forCtrler.setErrorEpty_myEmail(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_shopName === true || forCtrler.adminInfoNew_shopName === "") {
			if (forCtrler.adminInfoNew_shopName === "" && fromBtSave) {
				forCtrler.setErrorEpty_shopName(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_shopSt1 === true || forCtrler.adminInfoNew_shopSt1 === "") {
			if (forCtrler.adminInfoNew_shopSt1 === "" && fromBtSave) {
				forCtrler.setErrorEpty_shopSt1(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_shopCp === true || forCtrler.adminInfoNew_shopCp === "") {
			if (forCtrler.adminInfoNew_shopCp === "" && fromBtSave) {
				forCtrler.setErrorEpty_shopCp(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_shopCt === true || forCtrler.adminInfoNew_shopCt === "") {
			if (forCtrler.adminInfoNew_shopCt === "" && fromBtSave) {
				forCtrler.setErrorEpty_shopCt(true);
			}
			errorAll = true;
		}

		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveInfo = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			// let req = 'https://nominatim.openstreetmap.org/search?format=json&q='
			// 	+ forCtrler.adminInfoNew_shopSt1 + ' '
			// 	+ forCtrler.adminInfoNew_shopCp + ' '
			// 	+ forCtrler.adminInfoNew_shopCt + ' ';

			// // fetch('https://nominatim.openstreetmap.org/search?format=json&q=nozay 91620')
			// fetch(req)
			// 	.then(response => response.json())
			// 	.then(data => {
			// 		forCtrler.adminInfoNew_shopCo = '@'
			// 			+ data[0].lat + ','
			// 			+ data[0].lon + ',10z';
			// })

			await defaultQueryFn({
				query: "admin_infos/1",
				typeReq: "PUT",
				args: {
					l_fb: forCtrler.adminInfoNew_linkFb,
					l_ig: forCtrler.adminInfoNew_linkIg,
					l_tt: forCtrler.adminInfoNew_linkTt,
					l_pt: forCtrler.adminInfoNew_linkPt,
					m_email: forCtrler.adminInfoNew_myEmail,
					s_name: forCtrler.adminInfoNew_shopName,
					s_a_s1: forCtrler.adminInfoNew_shopSt1,
					s_a_s2: forCtrler.adminInfoNew_shopSt2,
					s_a_cp: forCtrler.adminInfoNew_shopCp,
					s_a_c: forCtrler.adminInfoNew_shopCt,
					s_a_co: forCtrler.adminInfoNew_shopCo,
					s_a_t: forCtrler.adminInfoNew_shopPh,
					s_a_e: forCtrler.adminInfoNew_shopMa,
					s_l: forCtrler.adminInfoNew_shopSi,
					pc_tm: forCtrler.adminInfoNew_pc_tm,
					pc_tp: forCtrler.adminInfoNew_pc_tp,
					pc_dm: forCtrler.adminInfoNew_pc_dm,
					pc_dp: forCtrler.adminInfoNew_pc_dp,
					pa_tm: forCtrler.adminInfoNew_pa_tm,
					pa_tp: forCtrler.adminInfoNew_pa_tp,
					pa_dm: forCtrler.adminInfoNew_pa_dm,
					pa_dp: forCtrler.adminInfoNew_pa_dp,
					ph_tm: forCtrler.adminInfoNew_ph_tm,
					ph_tp: forCtrler.adminInfoNew_ph_tp,
					ph_dm: forCtrler.adminInfoNew_ph_dm,
					ph_dp: forCtrler.adminInfoNew_ph_dp,
					s_a_cn: forCtrler.adminInfoNew_s_a_cn,
				},
				enable: true,
				special: null
			})
				.then((result) => {
					globalStore.dispatch(setAdminInfos(
						result && result.data && result.data.result ? result.data.result : null
					));
					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleChange_linkIg = (event) => {
		// forCtrler.setAdminInfoNew_linkIg(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_linkIg(true);
		} else {
			forCtrler.setErrorEpty_linkIg(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_linkIg(event.target.value));
	};

	const handleChange_linkFb = (event) => {
		// forCtrler.setAdminInfoNew_linkFb(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_linkFb(true);
		} else {
			forCtrler.setErrorEpty_linkFb(false);
		}
		globalStore.dispatch(setFAdmInf_linkFb(event.target.value));
	};

	const handleChange_linkTt = (event) => {
		// forCtrler.setAdminInfoNew_linkTt(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_linkTt(true);
		} else {
			forCtrler.setErrorEpty_linkTt(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_linkTt(event.target.value));
	};

	const handleChange_linkPt = (event) => {
		// forCtrler.setAdminInfoNew_linkPt(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_linkPt(true);
		} else {
			forCtrler.setErrorEpty_linkPt(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_linkPt(event.target.value));
	};

	const handleChange_myEmail = (event) => {
		// forCtrler.setAdminInfoNew_myEmail(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_myEmail(true);
		} else {
			forCtrler.setErrorEpty_myEmail(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_myEmail(event.target.value));
	};

	const handleChange_shopName = (event) => {
		// forCtrler.setAdminInfoNew_shopName(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_shopName(true);
		} else {
			forCtrler.setErrorEpty_shopName(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_shopName(event.target.value));
	};

	const handleChange_shopSt1 = (event) => {
		// forCtrler.setAdminInfoNew_shopSt1(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_shopSt1(true);
		} else {
			forCtrler.setErrorEpty_shopSt1(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_shopSt1(event.target.value));
	};

	const handleChange_shopSt2 = (event) => {
		// forCtrler.setAdminInfoNew_shopSt2(event.target.value);
		globalStore.dispatch(setFAdmInf_shopSt2(event.target.value));
	};

	const handleChange_shopCp = (event) => {
		// forCtrler.setAdminInfoNew_shopCp(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_shopCp(true);
		} else {
			forCtrler.setErrorEpty_shopCp(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_shopCp(event.target.value));
	};

	const handleChange_shopCt = (event) => {
		// forCtrler.setAdminInfoNew_shopCt(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_shopCt(true);
		} else {
			forCtrler.setErrorEpty_shopCt(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmInf_shopCt(event.target.value));
	};

	const handleChange_shopPh = (event) => {
		// forCtrler.setAdminInfoNew_shopPh(event.target.value);
		globalStore.dispatch(setFAdmInf_shopPh(event.target.value));
	};

	const handleChange_shopMa = (event) => {
		// forCtrler.setAdminInfoNew_shopMa(event.target.value);
		globalStore.dispatch(setFAdmInf_shopMa(event.target.value));
	};

	const handleChange_shopSi = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_shopSi(event.target.value));
	};

	const handleChange_pc_tm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pc_tm(event.target.value));
	};

	const handleChange_pc_tp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pc_tp(event.target.value));
	};

	const handleChange_pc_dm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pc_dm(event.target.value));
	};

	const handleChange_pc_dp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pc_dp(event.target.value));
	};

	const handleChange_pa_tm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pa_tm(event.target.value));
	};

	const handleChange_pa_tp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pa_tp(event.target.value));
	};

	const handleChange_pa_dm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pa_dm(event.target.value));
	};

	const handleChange_pa_dp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_pa_dp(event.target.value));
	};

	const handleChange_ph_tm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_ph_tm(event.target.value));
	};

	const handleChange_ph_tp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_ph_tp(event.target.value));
	};

	const handleChange_ph_dm = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_ph_dm(event.target.value));
	};

	const handleChange_ph_dp = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_ph_dp(event.target.value));
	};

	const handleChange_s_a_cn = (event) => {
		// forCtrler.setAdminInfoNew_shopSi(event.target.value);
		globalStore.dispatch(setFAdmInf_s_a_cn(event.target.value));
	};

	

	return {
		handleSaveInfo: handleSaveInfo,
		handleChange_linkIg: handleChange_linkIg,
		handleChange_linkFb: handleChange_linkFb,
		handleChange_linkTt: handleChange_linkTt,
		handleChange_linkPt: handleChange_linkPt,
		handleChange_myEmail: handleChange_myEmail,
		handleChange_shopName: handleChange_shopName,
		handleChange_shopSt1: handleChange_shopSt1,
		handleChange_shopSt2: handleChange_shopSt2,
		handleChange_shopCp: handleChange_shopCp,
		handleChange_shopCt: handleChange_shopCt,
		handleChange_shopPh: handleChange_shopPh,
		handleChange_shopMa: handleChange_shopMa,
		handleChange_shopSi: handleChange_shopSi,
		handleChange_pc_tm: handleChange_pc_tm,
		handleChange_pc_tp: handleChange_pc_tp,
		handleChange_pc_dm: handleChange_pc_dm,
		handleChange_pc_dp: handleChange_pc_dp,
		handleChange_pa_tm: handleChange_pa_tm,
		handleChange_pa_tp: handleChange_pa_tp,
		handleChange_pa_dm: handleChange_pa_dm,
		handleChange_pa_dp: handleChange_pa_dp,
		handleChange_ph_tm: handleChange_ph_tm,
		handleChange_ph_tp: handleChange_ph_tp,
		handleChange_ph_dm: handleChange_ph_dm,
		handleChange_ph_dp: handleChange_ph_dp,
		handleChange_s_a_cn: handleChange_s_a_cn,
	};
} 

export default AdminManageInfo_ctrl;
