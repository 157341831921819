
function Logo_ctrl(forCtrler) {

	const hamdleClickOnLogo = () => {
		forCtrler.navigate("/creations");
	};

	return {
		hamdleClickOnLogo: hamdleClickOnLogo,
	};
} 

export default Logo_ctrl;