
import { useSelector } from 'react-redux';

import {
	globalStore,
	getActuPara,
	getAdminInfos,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';
import SetHeader from '../../../generaltools/setheader.js';



import { HelmetComp } from '../../compoment/';

import {
	// StyGeneralPageArticle,
	StyGeneralPageHeader,
	StyGeneralPageH1,
	StyGeneralPageMain,
	StyActuPageDiv,
	StyActuImgImg,
	StyActuDateP,
	StyActuRdvP,
	StyActuTextP,
} from '../../../style/';

function Actu({location, actuText, onClickActuManage}) {

	const actuPara = useSelector(getActuPara(globalStore.getState())) || {};

	const admin_infos = useSelector(getAdminInfos(globalStore.getState())) || {};
	
	const headerPage = Getter.getHeaderPage({pageName: 'ACTU', info: admin_infos, actuPara: actuPara});
	
	const datetime_start = Getter.getDatetime(actuPara.dt_start);
	const datetime_end = Getter.getDatetime(actuPara.dt_end);


        const titlePage = SetHeader.replaceAddr(actuPara.title, admin_infos.shop_address_city, admin_infos.shop_address_city_near);
        const descrPage = SetHeader.replaceAddr(actuPara.descr, admin_infos.shop_address_city, admin_infos.shop_address_city_near);


	return (
		<div>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<StyGeneralPageHeader>
				<StyGeneralPageH1>{titlePage}</StyGeneralPageH1>
			</StyGeneralPageHeader>
			<StyGeneralPageMain>
				<StyActuPageDiv>
					{
						Getter.menuDisplayActu(actuPara) === true && actuPara.img_path !== undefined ? (
								<figure>
								  <StyActuImgImg src={headerPage.img} alt={actuPara.actu_alt_img} itemProp="image"/>
								  <figcaption>{actuPara.actu_lege_img}</figcaption>
								</figure>
							) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (
								<div>
									<h2 itemProp="name">{actuPara.title}</h2>
									<StyActuTextP>{descrPage}</StyActuTextP>
								</div>
							) : (
								<StyActuTextP>{headerPage.descr}</StyActuTextP>
							)
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (
							<section itemProp="location" itemScope itemType="https://schema.org/Place">
								<h3>Adresse :</h3>
								{
									actuPara.shop_addr && actuPara.shop_addr === true ? (
										<StyActuRdvP itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
											<span itemProp="streetAddress">{admin_infos.shop_address_street1}</span>,<br/>
											<span>{admin_infos.shop_address_street2}</span>,<br/>
											<span itemProp="postalCode">{admin_infos.shop_address_cp}</span> <span itemProp="addressLocality">{admin_infos.shop_address_city}</span>
										</StyActuRdvP>
									) : (
										<StyActuRdvP itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
											<span itemProp="streetAddress">{actuPara.loc_addr}</span>,<br/>
											<span itemProp="postalCode">{actuPara.loc_cp}</span> <span itemProp="addressLocality">{actuPara.loc_city}</span>
										</StyActuRdvP>
									)
								}
							</section>
						) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (
								<section>
									<h3>Dates :</h3>
									<StyActuDateP>Début : <time dateTime="[YYYY-MM-DDTHH:MM]" itemProp="startDate">{datetime_start}</time></StyActuDateP>
                					<StyActuDateP>Fin : <time dateTime="[YYYY-MM-DDTHH:MM]" itemProp="endDate">{datetime_end}</time></StyActuDateP>
								</section>
							) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true && actuPara.actu_note_spe && actuPara.actu_note_spe !== "" ? (
								<section>
									<h3>Informations supplémentaires :</h3>
                					<p>actuPara.actu_note_spe</p>
                				</section>
							) : ''
					}
				</StyActuPageDiv>
			</StyGeneralPageMain>
		</div>
	);
} 

export default Actu;



		// <div>
		// 	<HelmetComp
		// 		title = {headerPage.title}
		// 		description = {headerPage.descr}
		// 		image = {headerPage.img} />
		// 	<StyGeneralPageHeader>
		// 		<StyGeneralPageH1>{actuPara.title}</StyGeneralPageH1>
		// 	</StyGeneralPageHeader>
		// 	<StyGeneralPageMain>
		// 		<StyActuPageDiv>
		// 			{
		// 				Getter.menuDisplayActu(actuPara) === true && actuPara.img_path !== undefined ? (
		// 						<figure>
		// 						  <StyActuImgImg src={headerPage.img} alt={headerPage.descr} />
		// 						  <figcaption>{headerPage.title}</figcaption>
		// 						</figure>
		// 					) : ''
		// 			}
		// 			{
		// 				Getter.menuDisplayActu(actuPara) === true ? (
		// 						<StyActuDateP>
		// 							Du <time datetime={datetime_start}>{actuPara.dt_start}</time> au <time datetime={datetime_end}>{actuPara.dt_end}</time>
		// 						</StyActuDateP>
		// 					) : ''
		// 			}
		// 			{
		// 				Getter.menuDisplayActu(actuPara) === true ? (
		// 					<div>
		// 						{
		// 							actuPara.shop_addr && actuPara.shop_addr === true ? (
		// 								<StyActuRdvP>Rendez-vous au {admin_infos.shop_name}</StyActuRdvP>
		// 							) : (
		// 								<StyActuRdvP>Rendez-vous au {actuPara.loc_addr}, {actuPara.loc_cp} {actuPara.loc_city}</StyActuRdvP>
		// 							)
		// 						}
		// 					</div>
		// 				) : ''
		// 			}
		// 			{
		// 				Getter.menuDisplayActu(actuPara) === true ? (<StyActuTextP>{actuPara.descr}</StyActuTextP>) : (<StyActuTextP>{headerPage.descr}</StyActuTextP>)
		// 			}
		// 		</StyActuPageDiv>
		// 	</StyGeneralPageMain>
		// </div>
