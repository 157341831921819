
// // console.log("globalvar => url =", window.location.href);

const url = window.location.href.split('/');

// const BASE_DOM = url[0];
// // const BASE_DOM = "http://localhost";

// // const BASE_DOM = "https://bluevirus666.fr";
// // const BASE_PORT = "3001";
// const BASE_PORT = "61366";
// // const BASE_URL = BASE_DOM.concat(":").concat(BASE_PORT).concat("/api/");
// // const BASE_URL_SIMPLE = BASE_DOM.concat(":").concat(BASE_PORT).concat("/api");

// const BASE_URL_SIMPLE = BASE_DOM.concat("/api");
// //const BASE_URL_SIMPLE = BASE_DOM.concat(":").concat(BASE_PORT);
// const BASE_URL = BASE_URL_SIMPLE.concat("/");

// for localhost
// const BASE_DOM = "http://localhost";
// const BASE_PORT = "61366";
// const BASE_URL_SIMPLE = BASE_DOM.concat(":").concat(BASE_PORT);
// const BASE_URL = BASE_URL_SIMPLE.concat("/");

// for server
const BASE_DOM = url[0];
const BASE_PORT = "61366";
const BASE_URL_SIMPLE = BASE_DOM.concat("/api");
const BASE_URL = BASE_URL_SIMPLE.concat("/");



export {
	BASE_URL,
	BASE_URL_SIMPLE,
	BASE_PORT,
	url,
};
