
import {
	createRoutesFromElements,
	createBrowserRouter,
	RouterProvider,
	Route,
	Outlet,
	ScrollRestoration
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
	globalStore,
	getAllSection,
	getAllCategory,
	getAllItem,
	getAboutParagraphs,
} from '../../globalstate';

import { Header } from '../header';
import { Footer } from '../footer';
import {
	ErrorNotFound,
	Welcome,
	Category,
	Item,
	About,
	Contact,
	Admin,
	Actu,
	Siteplan,
} from './';

import {
	StyMainContentDiv
} from '../../style/';

import { Main_ctrl } from '../../controler/';


function Main() {

	const forCtrler = {
	};
	const ctrler = Main_ctrl(forCtrler);

	useEffect(() => {
		ctrler.getMainAllSection();
/* eslint-disable */
	}, []);

	useEffect(() => {
		ctrler.getMainAllCategory();
/* eslint-disable */
	}, []);

	useEffect(() => {
		ctrler.getMainAllItem();
/* eslint-disable */
	}, []);

	useEffect(() => {
		ctrler.getMainActuPara();
/* eslint-disable */
	}, []);

	useEffect(() => {
		ctrler.getAboutParagraph();
/* eslint-disable */
	}, []);

	const elementsTree = {
		sections: useSelector(getAllSection(globalStore.getState())) || [],
		categories: useSelector(getAllCategory(globalStore.getState())) || [],
		items: useSelector(getAllItem(globalStore.getState())) || [],
	};

	const about_texts = useSelector(getAboutParagraphs(globalStore.getState())) || [];

	const MainRender = () => (
		<div>
			<Header />
			<StyMainContentDiv>
		    	<Outlet />
		    	<Footer />
	    	</StyMainContentDiv>
	    	<ScrollRestoration />
		</div>
	);

	const mainRouter = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<MainRender />} errorElement={<ErrorNotFound />} >
	    		<Route element={<Header />} />
				<Route path="/" element={<Welcome elementsTree={elementsTree} />} />
				<Route path="/creations" element={<Welcome elementsTree={elementsTree} />} />
				<Route path="/categorie/:slug" element={<Category elementsTree={elementsTree} />} />
				<Route path="/realisation/:slug" element={<Item elementsTree={elementsTree} />} />
				<Route path="/chronique/:slug" element={<About location='ABOUT' aboutAll={about_texts} />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/evenement" element={<Actu />} />
				<Route path="/siteplan" element={<Siteplan elementsTree={elementsTree} aboutAll={about_texts} />} />
				<Route path="/bluevirus" element={<Admin elementsTree={elementsTree} />} />
			</Route>
		)
	);

	return (
		<RouterProvider router={mainRouter} />
	);
}

export default Main;
