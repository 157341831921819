
// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	useParams,
	useNavigate,
} from 'react-router-dom';

import { Getter } from '../../../generaltools';

import {
	globalStore,
	getAboutParagraphs,
	getAdminInfos,
	getCurrentAbout,
	getAdminCurrAbout,
} from '../../../globalstate';

import {
	MenuLeft,
	HelmetComp
} from '../../compoment/';

import {
	StyAboutPlaceDiv,
	StyAboutContentParaPlaceDiv,
	StyAboutContentParaContentDiv,
	StyAboutContentParaContentImgDiv,
	StyAboutContentParaContentImgImg,
	StyAboutContentParaContentTextP,
	StyGeneralButtonAddButton,
	StyAboutPageHeader,
	StyAboutPageH1,
} from '../../../style/';

import { About_ctrl } from '../../../controler/';

function About({location, about_text, onClickAboutText, onClickAddAboutText}) {
	const initAbout = {
		title: "",
		title_force: 0,
		content: "",
		img_path: "",
		order_place: 0,
		enable: true,
		is_delete: false,
		slug: "",
		no_article: 0,
	};

	const aboutAll = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	const admin_info = useSelector(getAdminInfos(globalStore.getState()));

	// aboutAll = [{
	// 	id: 1,
	// 	title: "1 L'hygienne selon moi, ca va decoiffer les amis",
	// 	title_force: 1,
	// 	content: "lorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla bla",
	// 	img_path: "",
	// 	order_place: 1,
	// 	enable: true,
	// 	is_delete: false,
	// 	slug: "111hh",
	// 	no_article: 1,
	// },{
	// 	id: 2,
	// 	title: "2 L'hygienne selon moi, ca va decoiffer les amis",
	// 	title_force: 1,
	// 	content: "lorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla bla",
	// 	img_path: "",
	// 	order_place: 1,
	// 	enable: true,
	// 	is_delete: false,
	// 	slug: "222hh",
	// 	no_article: 1,
	// },{
	// 	id: 3,
	// 	title: "3 L'hygienne selon moi, ca va decoiffer les amis",
	// 	title_force: 1,
	// 	content: "lorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla bla",
	// 	img_path: "/scfkfl",
	// 	order_place: 1,
	// 	enable: true,
	// 	is_delete: false,
	// 	slug: "333hh",
	// 	no_article: 1,
	// },{
	// 	id: 4,
	// 	title: "4 L'hygienne selon moi, ca va decoiffer les amis",
	// 	title_force: 1,
	// 	content: "lorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla bla",
	// 	img_path: "",
	// 	order_place: 1,
	// 	enable: true,
	// 	is_delete: false,
	// 	slug: "444hh",
	// 	no_article: 1,
	// }];

	const {slug} = useParams();
	const navigate = useNavigate();

	let currentAdminAbout = useSelector(getAdminCurrAbout(globalStore.getState()));
	let currentAbout = useSelector(getCurrentAbout(globalStore.getState())) || about_text || initAbout;
	// const about_text = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	
	// const headerPage = Getter.getHeaderPage({pageName: 'ABOUT', aboutParas: about_texts});
	const headerPage = Getter.getHeaderPage({pageName: 'ABOUT', aboutPara: currentAbout, info: admin_info});

	const forCtrler = {
		location: location,
		// about_texts: about_texts,
		aboutAll: aboutAll,
		about_text: currentAbout || about_text,
		navigate: navigate,
		slugAbout: slug,
		initAbout: initAbout,
	};
	const ctrler = About_ctrl(forCtrler);

	if (!currentAbout || !currentAbout.id || !currentAbout.slug) {
		currentAbout = ctrler.getAbout();
	}


	// currentAbout = {
	// 	id: 2,
	// 	title: "toto fait du velo, titi en fait aussi et tutu l'a dans le c...",
	// 	title_force: 1,
	// 	content: "lorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla blalorem upsum bla bla bla",
	// 	img_path: "",
	// 	order_place: 1,
	// 	enable: true,
	// 	is_delete: false,
	// 	slug: "hh",
	// 	no_article: 1,
	// };

// 	useEffect(() => {
// 		ctrler.getAboutParagraph();
// /* eslint-disable */
// 	}, []);


	return (
		<StyAboutPlaceDiv>
			{
				location === 'ADMIN' ? (
						<div>
							<StyGeneralButtonAddButton onClick={onClickAddAboutText}>Ajout Paragraphe</StyGeneralButtonAddButton>
							{
								aboutAll.length !== 0 ? (
										aboutAll.map((about_item_main, liId) => {
											liId++
											if (about_item_main.title_force === 1) {
												return (
													<StyAboutContentParaPlaceDiv key={liId}>
														{ ctrler.putTitleForce(about_item_main.id, about_item_main.title_force, about_item_main.title, true, location) }
														{
															currentAdminAbout && currentAdminAbout.no_article === about_item_main.no_article && aboutAll.map((about_item, liId2) => {
																liId2++
																if (about_item.no_article === about_item_main.no_article && about_item.id !== about_item_main.id) {
																	return (<div key={liId2}>{ ctrler.putTitleForce(about_item.id, about_item.title_force, about_item.title, true, location) }</div>)
																} else {
																	return (<div key={liId2}></div>)
																}
															})
														}
													</StyAboutContentParaPlaceDiv>
												)
											} else {
												return (<div key={liId}></div>)
											}
										})
									) : (<div></div>)
							}
						</div>
					) : (
						<div>
							<HelmetComp
								title = {headerPage.title}
								description = {headerPage.descr}
								image = {headerPage.img} />

							{
								aboutAll.length !== 0 ? (<MenuLeft location={location} aboutAll={aboutAll} currentAbout={currentAbout} onClickAboutTitle={ctrler.onClickMenuleftAbout} />) : ''
							}

							{
								aboutAll.length !== 0 ? (
										aboutAll.map((about_item, liId) => {
											liId++
											if (about_item.enable === true && about_item.no_article === currentAbout.no_article) {
												return (
													<StyAboutContentParaPlaceDiv key={liId}>
														{ ctrler.putTitleForce(about_item.id, about_item.title_force, about_item.title, false, location) }
														<StyAboutContentParaContentDiv>
															{
																about_item.img_path && about_item.img_path !== '' ? (
																	<StyAboutContentParaContentImgDiv>
																		<picture>
																			<figure>
																				<StyAboutContentParaContentImgImg src={Getter.getImgPath(about_item.img_path)} alt={about_item.title} />
																				<figcaption>{about_item.title}</figcaption>
																			</figure>
																		</picture>
																	</StyAboutContentParaContentImgDiv>
																) : (
																	<div></div>
																)
															}
															<StyAboutContentParaContentTextP>{about_item.content}</StyAboutContentParaContentTextP>
														</StyAboutContentParaContentDiv>
													</StyAboutContentParaPlaceDiv>
												)
											} else {
												return (<div key={liId}></div>)
											}
										})
									) : (
										<StyAboutPageHeader>
											<StyAboutPageH1>{headerPage.title_page}</StyAboutPageH1>
											<p>{headerPage.descr_page}</p>
										</StyAboutPageHeader>
									)
							}
						</div>
					)
			}
		</StyAboutPlaceDiv>
	);
} 

export default About;



		// <StyAboutPlaceDiv>
		// 	<HelmetComp
		// 		title = {headerPage.title}
		// 		description = {headerPage.descr}
		// 		image = {headerPage.img} />

		// 	{
		// 		aboutAll.length !== 0 ? (<MenuLeft location={location} aboutAll={aboutAll} currentAbout={currentAbout} onClickAboutTitle={ctrler.onClickMenuleftAbout} />) : ''
		// 	}
			
		// 	{
		// 		aboutAll.length !== 0 ? (
		// 			aboutAll.map((about_item, liId) => {
		// 				liId++;
		// 				if (about_item.enable === true || (location === 'ADMIN')) {
		// 					if (about_item.no_article === currentAbout.no_article) {
		// 						return (
		// 							<StyAboutContentParaPlaceDiv key={liId}>
		// 								{ ctrler.putTitleForce(about_item.id, about_item.title_force, about_item.title, onClickAboutText, location) }
		// 								{
		// 									location !== 'ADMIN' ? (
		// 										<StyAboutContentParaContentDiv>
		// 											{
		// 												about_item.img_path && about_item.img_path !== '' ? (
		// 													<StyAboutContentParaContentImgDiv>
		// 														<picture>
		// 															<figure>
		// 																<StyAboutContentParaContentImgImg src={Getter.getImgPath(about_item.img_path)} alt={about_item.title} />
		// 																<figcaption>{about_item.title}</figcaption>
		// 															</figure>
		// 														</picture>
		// 													</StyAboutContentParaContentImgDiv>
		// 												) : (
		// 													<div></div>
		// 												)
		// 											}
		// 											<StyAboutContentParaContentTextP>{about_item.content}</StyAboutContentParaContentTextP>
		// 										</StyAboutContentParaContentDiv>
		// 									) : ( <div></div> )
		// 								}
		// 							</StyAboutContentParaPlaceDiv>
		// 						)
		// 					} else {
		// 						return (<div key={liId}></div>)
		// 					}
		// 				} else {
		// 					return (<div key={liId}></div>)
		// 				}
		// 			})
		// 		) : (
		// 			<StyAboutPageHeader>
		// 				<StyAboutPageH1>{headerPage.title}</StyAboutPageH1>
		// 				<p>{headerPage.descr}</p>
		// 			</StyAboutPageHeader>
		// 		)
		// 	}
		// </StyAboutPlaceDiv>


















		// <StyAboutPlaceDiv>
		// 	<HelmetComp
		// 		title = {headerPage.title}
		// 		description = {headerPage.descr}
		// 		image = {headerPage.img} />
		// 	<MenuLeft location={location} aboutAll={aboutAll} />
		// 	{
		// 		location === 'ADMIN' ? (
		// 			<StyGeneralButtonAddButton onClick={onClickAddAboutText}>Ajout Paragraphe</StyGeneralButtonAddButton>
		// 		) : (
		// 			<StyAboutPageHeader>
		// 				<StyAboutPageH1>{headerPage.title}</StyAboutPageH1>
		// 				<p>{headerPage.descr}</p>
		// 			</StyAboutPageHeader>
		// 		)
		// 	}
		// 	{
		// 		about_texts.length !== 0 ? (
		// 			about_texts.map((about_text_item, liId) => {
		// 				liId++;
		// 				if (about_text_item.enable === true || (location === 'ADMIN')) {
		// 					return (
		// 						<StyAboutContentParaPlaceDiv key={liId}>
		// 							{ ctrler.putTitleForce(about_text_item.id, about_text_item.title_force, about_text_item.title, onClickAboutText, location) }
		// 							{
		// 								location !== 'ADMIN' ? (
		// 									<StyAboutContentParaContentDiv>
		// 										{
		// 											about_text_item.img_path && about_text_item.img_path !== '' ? (
		// 												<StyAboutContentParaContentImgDiv>
		// 													<picture>
		// 														<figure>
		// 															<StyAboutContentParaContentImgImg src={Getter.getImgPath(about_text_item.img_path)} alt={about_text_item.title} />
		// 															<figcaption>{about_text_item.title}</figcaption>
		// 														</figure>
		// 													</picture>
		// 												</StyAboutContentParaContentImgDiv>
		// 											) : (
		// 												<div></div>
		// 											)
		// 										}
		// 										<StyAboutContentParaContentTextP>{about_text_item.content}</StyAboutContentParaContentTextP>
		// 									</StyAboutContentParaContentDiv>
		// 								) : ( <div></div> )
		// 							}
		// 						</StyAboutContentParaPlaceDiv>
		// 					)
		// 				} else {
		// 					return (<div key={liId}></div>)
		// 				}
		// 			})
		// 		) : (<div></div>)
		// 	}
		// </StyAboutPlaceDiv>