
const getAdmConnected = () => {
	return (state) => state.admConnected;
};

const getCurrentCategory = () => {
	return (state) => state.currCategory;
};
const getCurrentSection = () => {
	return (state) => state.currSection;
};
const getCurrentItem = () => {
	return (state) => state.currItem;
};
const getCurrentAbout = () => {
	return (state) => state.currAbout;
};

const getAboutParagraphs = () => {
	return (state) => state.aboutParagraphs;
};
const getAdminInfos = () => {
	return (state) => state.adminInfos;
};
const getActuPara = () => {
	return (state) => state.actuPara;
};

const getAllSection = () => {
	return (state) => state.allSection;
};
const getAllCategory = () => {
	return (state) => state.allCategory;
};
const getAllItem = () => {
	return (state) => state.allItem;
};

const getAdminCurrSection = () => {
	return (state) => state.adminCurrSection;
};
const getAdminCurrCategory = () => {
	return (state) => state.adminCurrCategory;
};
const getAdminCurrItem = () => {
	return (state) => state.adminCurrItem;
};
const getAdminCurrAbout = () => {
	return (state) => state.adminCurrAbout;
};
const getAdminIsActive = () => {
	return (state) => state.adminIsActive;
};
const getAdminPartActive = () => {
	return (state) => state.adminPartActive;
};
const getAdminModeAddMod = () => {
	return (state) => state.adminModeAddMod;
};

const getFAdmInf_linkIg = () => {
	return (state) => state.fAdmInf_linkIg;
};
const getFAdmInf_linkFb = () => {
	return (state) => state.fAdmInf_linkFb;
};
const getFAdmInf_linkTt = () => {
	return (state) => state.fAdmInf_linkTt;
};
const getFAdmInf_linkPt = () => {
	return (state) => state.fAdmInf_linkPt;
};
const getFAdmInf_myEmail = () => {
	return (state) => state.fAdmInf_myEmail;
};
const getFAdmInf_shopName = () => {
	return (state) => state.fAdmInf_shopName;
};
const getFAdmInf_shopSt1 = () => {
	return (state) => state.fAdmInf_shopSt1;
};
const getFAdmInf_shopSt2 = () => {
	return (state) => state.fAdmInf_shopSt2;
};
const getFAdmInf_shopCp = () => {
	return (state) => state.fAdmInf_shopCp;
};
const getFAdmInf_shopCt = () => {
	return (state) => state.fAdmInf_shopCt;
};
const getFAdmInf_shopCo = () => {
	return (state) => state.fAdmInf_shopCo;
};
const getFAdmInf_shopPh = () => {
	return (state) => state.fAdmInf_shopPh;
};
const getFAdmInf_shopMa = () => {
	return (state) => state.fAdmInf_shopMa;
};
const getFAdmInf_shopSi = () => {
	return (state) => state.fAdmInf_shopSi;
};
const getFAdmInf_pc_tm = () => {
	return (state) => state.fAdmInf_pc_tm;
};
const getFAdmInf_pc_tp = () => {
	return (state) => state.fAdmInf_pc_tp;
};
const getFAdmInf_pc_dm = () => {
	return (state) => state.fAdmInf_pc_dm;
};
const getFAdmInf_pc_dp = () => {
	return (state) => state.fAdmInf_pc_dp;
};
const getFAdmInf_pa_tm = () => {
	return (state) => state.fAdmInf_pa_tm;
};
const getFAdmInf_pa_tp = () => {
	return (state) => state.fAdmInf_pa_tp;
};
const getFAdmInf_pa_dm = () => {
	return (state) => state.fAdmInf_pa_dm;
};
const getFAdmInf_pa_dp = () => {
	return (state) => state.fAdmInf_pa_dp;
};
const getFAdmInf_ph_tm = () => {
	return (state) => state.fAdmInf_ph_tm;
};
const getFAdmInf_ph_tp = () => {
	return (state) => state.fAdmInf_ph_tp;
};
const getFAdmInf_ph_dm = () => {
	return (state) => state.fAdmInf_ph_dm;
};
const getFAdmInf_ph_dp = () => {
	return (state) => state.fAdmInf_ph_dp;
};
const getFAdmInf_s_a_cn = () => {
	return (state) => state.fAdmInf_s_a_cn;
};

const getFActuPara_ti = () => {
	return (state) => state.fActuPara_ti;
};
const getFActuPara_dc = () => {
	return (state) => state.fActuPara_dc;
};
const getFActuPara_ip = () => {
	return (state) => state.fActuPara_ip;
};
const getFActuPara_ds = () => {
	return (state) => state.fActuPara_ds;
};
const getFActuPara_de = () => {
	return (state) => state.fActuPara_de;
};
const getFActuPara_sa = () => {
	return (state) => state.fActuPara_sa;
};
const getFActuPara_ad = () => {
	return (state) => state.fActuPara_ad;
};
const getFActuPara_cp = () => {
	return (state) => state.fActuPara_cp;
};
const getFActuPara_ci = () => {
	return (state) => state.fActuPara_ci;
};
const getFActuPara_tm = () => {
	return (state) => state.fActuPara_tm;
};
const getFActuPara_dm = () => {
	return (state) => state.fActuPara_dm;
};
const getFActuPara_ai = () => {
	return (state) => state.fActuPara_ai;
};
const getFActuPara_li = () => {
	return (state) => state.fActuPara_li;
};
const getFActuPara_ns = () => {
	return (state) => state.fActuPara_ns;
};

const getFAdmAbo_ti = () => {
	return (state) => state.fAdmAbo_ti;
};
const getFAdmAbo_tf = () => {
	return (state) => state.fAdmAbo_tf;
};
const getFAdmAbo_co = () => {
	return (state) => state.fAdmAbo_co;
};
const getFAdmAbo_ip = () => {
	return (state) => state.fAdmAbo_ip;
};
const getFAdmAbo_op = () => {
	return (state) => state.fAdmAbo_op;
};
const getFAdmAbo_na = () => {
	return (state) => state.fAdmAbo_na;
};
const getFAdmAbo_en = () => {
	return (state) => state.fAdmAbo_en;
};
const getFAdmAbo_de = () => {
	return (state) => state.fAdmAbo_de;
};
const getFAdmAbo_tm = () => {
	return (state) => state.fAdmAbo_tm;
};
const getFAdmAbo_dm = () => {
	return (state) => state.fAdmAbo_dm;
};

const getFAdmSec_na = () => {
	return (state) => state.fAdmSec_na;
};
const getFAdmSec_or = () => {
	return (state) => state.fAdmSec_or;
};
const getFAdmSec_en = () => {
	return (state) => state.fAdmSec_en;
};
const getFAdmSec_de = () => {
	return (state) => state.fAdmSec_de;
};

const getFAdmCat_na = () => {
	return (state) => state.fAdmCat_na;
};
const getFAdmCat_or = () => {
	return (state) => state.fAdmCat_or;
};
const getFAdmCat_ip = () => {
	return (state) => state.fAdmCat_ip;
};
const getFAdmCat_ai = () => {
	return (state) => state.fAdmCat_ai;
};
const getFAdmCat_si = () => {
	return (state) => state.fAdmCat_si;
};
const getFAdmCat_en = () => {
	return (state) => state.fAdmCat_en;
};
const getFAdmCat_de = () => {
	return (state) => state.fAdmCat_de;
};
const getFAdmCat_tm = () => {
	return (state) => state.fAdmCat_tm;
};
const getFAdmCat_tp = () => {
	return (state) => state.fAdmCat_tp;
};
const getFAdmCat_dm = () => {
	return (state) => state.fAdmCat_dm;
};
const getFAdmCat_dp = () => {
	return (state) => state.fAdmCat_dp;
};

const getFAdmIte_ti = () => {
	return (state) => state.fAdmIte_ti;
};
const getFAdmIte_ds = () => {
	return (state) => state.fAdmIte_ds;
};
const getFAdmIte_ta = () => {
	return (state) => state.fAdmIte_ta;
};
const getFAdmIte_co = () => {
	return (state) => state.fAdmIte_co;
};
const getFAdmIte_or = () => {
	return (state) => state.fAdmIte_or;
};
const getFAdmIte_ip = () => {
	return (state) => state.fAdmIte_ip;
};
const getFAdmIte_ci = () => {
	return (state) => state.fAdmIte_ci ;
};
const getFAdmIte_av = () => {
	return (state) => state.fAdmIte_av;
};
const getFAdmIte_en = () => {
	return (state) => state.fAdmIte_en;
};
const getFAdmIte_de = () => {
	return (state) => state.fAdmIte_de;
};
const getFAdmIte_tm = () => {
	return (state) => state.fAdmIte_tm;
};
const getFAdmIte_tp = () => {
	return (state) => state.fAdmIte_tp;
};
const getFAdmIte_dm = () => {
	return (state) => state.fAdmIte_dm;
};
const getFAdmIte_ai = () => {
	return (state) => state.fAdmIte_ai;
};
const getFAdmIte_lb = () => {
	return (state) => state.fAdmIte_lb;
};


export {
	getAdmConnected,
	
	getCurrentCategory,
	getCurrentSection,
	getCurrentItem,
	getCurrentAbout,

	getAboutParagraphs,
	getAdminInfos,
	getActuPara,

	getAllSection,
	getAllCategory,
	getAllItem,

	getAdminCurrSection,
	getAdminCurrCategory,
	getAdminCurrItem,
	getAdminCurrAbout,
	getAdminIsActive,
	getAdminPartActive,
	getAdminModeAddMod,

	getFAdmInf_linkIg,
	getFAdmInf_linkFb,
	getFAdmInf_linkTt,
	getFAdmInf_linkPt,
	getFAdmInf_myEmail,
	getFAdmInf_shopName,
	getFAdmInf_shopSt1,
	getFAdmInf_shopSt2,
	getFAdmInf_shopCp,
	getFAdmInf_shopCt,
	getFAdmInf_shopCo,
	getFAdmInf_shopPh,
	getFAdmInf_shopMa,
	getFAdmInf_shopSi,
	getFAdmInf_pc_tm,
	getFAdmInf_pc_tp,
	getFAdmInf_pc_dm,
	getFAdmInf_pc_dp,
	getFAdmInf_pa_tm,
	getFAdmInf_pa_tp,
	getFAdmInf_pa_dm,
	getFAdmInf_pa_dp,
	getFAdmInf_ph_tm,
	getFAdmInf_ph_tp,
	getFAdmInf_ph_dm,
	getFAdmInf_ph_dp,
	getFAdmInf_s_a_cn,

	getFActuPara_ti,
	getFActuPara_dc,
	getFActuPara_ip,
	getFActuPara_ds,
	getFActuPara_de,
	getFActuPara_sa,
	getFActuPara_ad,
	getFActuPara_cp,
	getFActuPara_ci,
	getFActuPara_tm,
	getFActuPara_dm,
	getFActuPara_ai,
	getFActuPara_li,
	getFActuPara_ns,

	getFAdmAbo_ti,
	getFAdmAbo_tf,
	getFAdmAbo_co,
	getFAdmAbo_ip,
	getFAdmAbo_op,
	getFAdmAbo_na,
	getFAdmAbo_en,
	getFAdmAbo_de,
	getFAdmAbo_tm,
	getFAdmAbo_dm,

	getFAdmSec_na,
	getFAdmSec_or,
	getFAdmSec_en,
	getFAdmSec_de,

	getFAdmCat_na,
	getFAdmCat_or,
	getFAdmCat_ip,
	getFAdmCat_ai,
	getFAdmCat_si,
	getFAdmCat_en,
	getFAdmCat_de,
	getFAdmCat_tm,
	getFAdmCat_tp,
	getFAdmCat_dm,
	getFAdmCat_dp,

	getFAdmIte_ti,
	getFAdmIte_ds,
	getFAdmIte_ta,
	getFAdmIte_co,
	getFAdmIte_or,
	getFAdmIte_ip,
	getFAdmIte_ci,
	getFAdmIte_av,
	getFAdmIte_en,
	getFAdmIte_de,
	getFAdmIte_tm,
	getFAdmIte_tp,
	getFAdmIte_dm,
	getFAdmIte_ai,
	getFAdmIte_lb,
}