
import { useSelector } from 'react-redux';

// import { Contact_ctrl } from '../../../controler/';
import { Getter } from '../../../generaltools';
import SetHeader from '../../../generaltools/setheader.js';

import {
	globalStore,
	getAdminInfos,
} from '../../../globalstate';

import { HelmetComp } from '../../compoment/';

import {
	StyContactPlaceDiv,
	StyContactLinksDiv,
	StyContactLinksP,
	// StyContactLinksSpan,
	// StyContactFormDiv,
	// StyContactFormForm,
	// StyContactFormLabel,
	// StyContactFormLabelSpan,
	// StyContactFormLabelInput,
	// StyContactFormLabelTextarea,
	// StyContactFormButton,
	StyGeneralPageH1,
	StyGeneralPageHeader,
} from '../../../style/';

function Contact() {

	const admin_infos = useSelector(getAdminInfos(globalStore.getState())) || {};
	const headerPage = Getter.getHeaderPage({pageName: 'CONTACT', info: admin_infos});

	// const forCtrler = {
	// };
	// const ctrler = Contact_ctrl(forCtrler);


        const titlePage = SetHeader.replaceAddr(admin_infos.conta_title_h1, admin_infos.shop_address_city, admin_infos.shop_address_city_near);
        const descrPage = SetHeader.replaceAddr(admin_infos.conta_descr_page, admin_infos.shop_address_city, admin_infos.shop_address_city_near);


	return (
		<StyContactPlaceDiv>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<StyGeneralPageHeader>
				<StyGeneralPageH1>{titlePage}</StyGeneralPageH1>
				<p>{descrPage}</p>
			</StyGeneralPageHeader>
			<StyContactLinksDiv>
				<StyContactLinksP>Pour toute demande d'information, de collaboration ou pour discuter de votre futur projet de tatouage, je vous invite à me contacter via mes réseaux sociaux. Je suis réactive et attentive à chaque message, afin de vous offrir une expérience personnalisée et professionnelle.</StyContactLinksP>
				<StyContactLinksP>Retrouvez-moi sur : Instagram, Facebook, et TikTok</StyContactLinksP>
				{
					admin_infos.my_email && admin_infos.my_email !== "" ? (
							<StyContactLinksP>Si vous voulez m'envoyer un mail, voici mon adresse : <a href="mailto:{admin_infos.my_email}">{admin_infos.my_email}</a></StyContactLinksP>
						) : (<p></p>)
				}
				<StyContactLinksP>Je suis impatiente de collaborer avec vous et de donner vie à vos idées à travers mon art. N'hésitez pas à consulter mon portfolio pour découvrir mes créations et vous inspirer pour votre prochain tatouage.</StyContactLinksP>
			</StyContactLinksDiv>
		</StyContactPlaceDiv>
	);
} 

export default Contact;

// <StyContactLinksP>N´hésitez pas à me contacter sur mes réseaux <StyContactLinksSpan>(liens en bas de page)</StyContactLinksSpan>.</StyContactLinksP>


		// <StyC0ontactPlaceDiv>
		// 	<StyContactLinksDiv>
		// 		<StyContactLinksP>N´hésitez pas à me contacter sur mes réseaux <StyContactLinksSpan>(liens en bas de page)</StyContactLinksSpan>.</StyContactLinksP>
		// 		<StyContactLinksP>Je suis active essentiellement sur Instagram, Facebook, Tiktok et Pinterest.</StyContactLinksP>
		// 		<StyContactLinksP>Si vous voulez tout de même m'envoyer un mail, veuillez complêter le formulaire ci-dessous :</StyContactLinksP>
		// 	</StyContactLinksDiv>
		// 	<StyContactFormDiv>
		// 		<StyContactFormForm>
		// 			<StyContactFormLabel>
		// 				<StyContactFormLabelSpan>E-mail :</StyContactFormLabelSpan>
		// 				<StyContactFormLabelInput type='email' name='emailFrom' />
		// 			</StyContactFormLabel>
		// 			<StyContactFormLabel>
		// 				<StyContactFormLabelSpan>Sujet :</StyContactFormLabelSpan>
		// 				<StyContactFormLabelInput type='text' name='subjectFrom' />
		// 			</StyContactFormLabel>
		// 			<StyContactFormLabel>
		// 				<StyContactFormLabelSpan>Contenu :</StyContactFormLabelSpan>
		// 				<StyContactFormLabelTextarea placeholder='Bonjour BlueVirus,je souhaiterais me faire tatouer.Cordialement.'>
		// 				</StyContactFormLabelTextarea>
		// 			</StyContactFormLabel>
		// 			<StyContactFormButton onClick={ctrler.handleSubmitContact}>Envoyer</StyContactFormButton>
		// 		</StyContactFormForm>
		// 	</StyContactFormDiv>
		// </StyC0ontactPlaceDiv>
