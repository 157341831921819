
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { useEffect } from 'react';

import { Getter } from '../../../generaltools';

import {
	ItemItem,
	MenuLeft,
	HelmetComp,
} from '../../compoment/';

import {
	globalStore,
	getCurrentCategory,
} from '../../../globalstate';

import {
	StyGeneralPagePlaceDiv,
	StyCategoryPlaceDiv,
	// StyCategoryTitleH2,
	StyCategoryItemPlaceDiv,
	StyCategoryItemListPlaceDiv,
	// StyGeneralPageMain,
	StyGeneralPageH1,
	StyGeneralPageHeader,
} from '../../../style/';

import { Category_ctrl } from '../../../controler/';


function Category({category_item, elementsTree}) {
	const cate = {
		name: "",
		order: 0,
		img_path: "",
		auto_img: true,
		section_id: 0,
		enable: true,
		slug: "",
		is_delete: false,
	};

	// const {id} = useParams();
	const {slug} = useParams();
	let currentCategory = useSelector(getCurrentCategory(globalStore.getState())) || category_item || cate;

	const forCtrler = {
		elementsTree: elementsTree,
		category_item: currentCategory || category_item,
		// idCate: id,
		slugCate: slug,
		initCate: cate,
	};
	const ctrler = Category_ctrl(forCtrler);

// 	useEffect(() => {
// 		ctrler.getCategory();
// /* eslint-disable */
// 	}, []);
	if (!currentCategory || !currentCategory.id|| !currentCategory.slug) {
		currentCategory = ctrler.getCategory();
	}
	
	const headerPage = Getter.getHeaderPage({
			pageName: 'CATEGORY',
			sections: elementsTree.sections,
			sectionId: currentCategory.section_id,
			categories: elementsTree.categories,
			categoryId: currentCategory.id,
			items: elementsTree.items,
		});

	return (
		<StyCategoryPlaceDiv>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<MenuLeft elementsTree={elementsTree} />
			<StyGeneralPagePlaceDiv>
				<article>
					<StyGeneralPageHeader>
						<StyGeneralPageH1>{currentCategory.categ_title_h1}</StyGeneralPageH1>
						<p>{currentCategory.categ_descr_page}</p>
					</StyGeneralPageHeader>
					<StyCategoryItemListPlaceDiv>
						<picture>
						{
							elementsTree.items.length !== 0 ? (
								elementsTree.items.map((itemItemListItem, liId) => {
									liId++;
									if (itemItemListItem.category_id === currentCategory.id && itemItemListItem.enable === true) {
										return (
											<StyCategoryItemPlaceDiv key={liId}>
												<ItemItem
													location={'MAIN'}
													item={itemItemListItem} />
											</StyCategoryItemPlaceDiv>
										)
									} else { return (<StyCategoryItemPlaceDiv key={liId}></StyCategoryItemPlaceDiv>) }
								})
							) : (<div></div>)
						}
						</picture>
					</StyCategoryItemListPlaceDiv>
				</article>
			</StyGeneralPagePlaceDiv>
		</StyCategoryPlaceDiv>
	);
} 

export default Category;


		// <StyCategoryPlaceDiv>
		// 	<HelmetComp
		// 		title = {headerPage.title}
		// 		description = {headerPage.descr}
		// 		image = {headerPage.img} />
		// 	<MenuLeft elementsTree={elementsTree} />
		// 	<StyGeneralPagePlaceDiv>
		// 		<article>
		// 			<StyGeneralPageHeader>
		// 				<StyGeneralPageH1>{headerPage.title}</StyGeneralPageH1>
		// 				<p>{headerPage.descr}</p>
		// 			</StyGeneralPageHeader>
		// 			<StyCategoryItemListPlaceDiv>
		// 				<picture>
		// 				{
		// 					elementsTree.items.length !== 0 ? (
		// 						elementsTree.items.map((itemItemListItem, liId) => {
		// 							liId++;
		// 							if (itemItemListItem.category_id === currentCategory.id && itemItemListItem.enable === true) {
		// 								return (
		// 									<StyCategoryItemPlaceDiv key={liId}>
		// 										<ItemItem
		// 											location={'MAIN'}
		// 											item={itemItemListItem} />
		// 									</StyCategoryItemPlaceDiv>
		// 								)
		// 							} else { return (<StyCategoryItemPlaceDiv key={liId}></StyCategoryItemPlaceDiv>) }
		// 						})
		// 					) : (<div></div>)
		// 				}
		// 				</picture>
		// 			</StyCategoryItemListPlaceDiv>
		// 		</article>
		// 	</StyGeneralPagePlaceDiv>
		// </StyCategoryPlaceDiv>