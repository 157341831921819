
import { defaultQueryFn } from '../../queryapi';

import {
	globalStore,
	setAllSection,
	setAllCategory,
	setAllItem,
	setActuPara,
	setAboutParagraphs,
} from '../../globalstate';


function Main_ctrl(forCtrler) {

	const getMainAllSection = async () => {
		await defaultQueryFn({
			query: "sections",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAllSection(
					result && result.data && result.data.result ? result.data.result : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const getMainAllCategory = async () => {
		await defaultQueryFn({
			query: "categories",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAllCategory(
					result && result.data && result.data.result ? result.data.result : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const getMainAllItem = async () => {
		await defaultQueryFn({
			query: "items",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAllItem(
					result && result.data && result.data.result ? result.data.result : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const getAboutParagraph = async () => {
		await defaultQueryFn({
			query: "about_paragraphs",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAboutParagraphs(
					result && result.data && result.data.result ? result.data.result : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const getMainActuPara = async () => {
		await defaultQueryFn({
			query: "actus",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setActuPara(
					result && result.data && result.data.result ? result.data.result[0] : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	return {
		getMainAllSection: getMainAllSection,
		getMainAllCategory: getMainAllCategory,
		getMainAllItem: getMainAllItem,
		getAboutParagraph: getAboutParagraph,
		getMainActuPara: getMainActuPara,
	};
} 

export default Main_ctrl;
