
//const defaultImg = '../img/tools/default_img.jpeg';
//const imgLogo = '../img/tools/logo.jpeg';
const defaultImg = "/static/media/logo.58e3f1846b695bedbb15.jpeg";
const imgLogo = "/static/media/logo.58e3f1846b695bedbb15.jpeg";
//const BASE_DOM = window.location.href.split('/')[0];
const BASE_DOM = "https://www.bluevirus666.com";

module.exports = class SetHeader {

	static replaceLocalhost = (url) => {
		if (url && url !== "" && url.includes("http://localhost:61366")) {
			return url.replace("http://localhost:61366", BASE_DOM);
		}
		return url;
	}

	static replaceAddr = (str, addr, addrn) => {
		let addr_str = "à " + addr;
		let addrn_str = "proche de " + addrn;
		let str2 = str;

		if (str && str !== "" && str !== undefined) {

			if (str.includes("@addr@")) {
				str2 = str.replace("@addr@", addr_str);
			} else {
				str2 = str;
			}
			if (str2.includes("@addrn@")) {
				return str2.replace("@addrn@", addrn_str);
			}
			
		}
		return str2;
	}

	static getHeaderPageWelcome = (info) => {
		// let newTitle = "";
		// let newDescr = "";
		// if (info !== undefined && info.shop_address_city !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
		// 	newTitle = "Tatouages Dark Art & Créations Uniques – BlueVirus666 à " + info.shop_address_city;
		// } else {
		// 	newTitle = "Tatouages Dark Art & Créations Uniques – BlueVirus666";
		// }
		// if (info !== undefined && info.shop_address_city !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
		// 	newDescr = "Explorez l’univers unique de BlueVirus666, tatoueuse spécialisée dans le Dark Art proche de " + info.shop_address_city_near + ". Découvrez mes tatouages personnalisés ainsi que mes créations sur vêtements et coques de téléphone.";
		// } else {
		// 	newDescr = "Explorez l’univers unique de BlueVirus666, tatoueuse spécialisée dans le Dark Art. Découvrez mes tatouages personnalisés ainsi que mes créations sur vêtements et coques de téléphone.";
		// }
		let headerPage = {
			title: this.replaceAddr(info.creat_title_meta, info.shop_address_city, info.shop_address_city_near),
			descr: this.replaceAddr(info.creat_descr_meta, info.shop_address_city, info.shop_address_city_near),
			img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

	static getHeaderPageContact = (info) => {
		// let title = '';
		// let descr = '';
		// if (info !== undefined && info.shop_address_city !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
		// 	title = "BlueVirus666 – Contactez Votre Tatoueuse Dark Art à " + info.shop_address_city;
		// } else {
		// 	title = "BlueVirus666 – Contactez Votre Tatoueuse Dark Art";
		// }
		// if (info !== undefined && info.shop_address_city !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
		// 	descr = "Contactez BlueVirus666, artiste tatoueuse spécialisée en Dark Art à " + info.shop_address_city + ". Pour vos projets de tatouages uniques ou collaborations artistiques, écrivez-moi dès aujourd’hui !";
		// } else {
		// 	descr = "Contactez BlueVirus666, artiste tatoueuse spécialisée en Dark Art. Pour vos projets de tatouages uniques ou collaborations artistiques, écrivez-moi dès aujourd’hui !";
		// }

		let headerPage = {
			title: this.replaceAddr(info.conta_title_meta, info.shop_address_city, info.shop_address_city_near),
			descr: this.replaceAddr(info.conta_descr_meta, info.shop_address_city, info.shop_address_city_near),
			img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

	static getHeaderPageAbout = (aboutPara, info) => {
		let inf = {
			chron_title_meta: "",
			chron_descr_meta: "",
			chron_title_h1: "",
			chron_descr_page: "",
		}
		if (info && info !== undefined) {
			inf = info;
		}


		let headerPage = {
			title: this.replaceAddr(inf.chron_title_meta, inf.shop_address_city, inf.shop_address_city_near),
			descr: this.replaceAddr(inf.chron_descr_meta, inf.shop_address_city, inf.shop_address_city_near),
			img: BASE_DOM.concat(defaultImg),
		};

		if (aboutPara.title !== undefined) {
			headerPage = {
				title: aboutPara.chron_title_meta,
				descr: aboutPara.chron_descr_meta,
				// img: BASE_DOM.concat(aboutPara.img_path) || BASE_DOM.concat(defaultImg),
				img: this.replaceLocalhost(aboutPara.img_path) || BASE_DOM.concat(defaultImg),
			};
		} else {
			headerPage = {
				title: this.replaceAddr(inf.chron_title_meta, inf.shop_address_city, inf.shop_address_city_near),
				descr: this.replaceAddr(inf.chron_descr_meta, inf.shop_address_city, inf.shop_address_city_near),
				title_page: this.replaceAddr(inf.chron_title_h1, inf.shop_address_city, inf.shop_address_city_near),
				descr_page: this.replaceAddr(inf.chron_descr_page, inf.shop_address_city, inf.shop_address_city_near),
				img: BASE_DOM.concat(defaultImg),
			};
		}


		// let headerPage = {
		// 	title: "Blog Tattoo Dark Art – Inspirations & Conseils par BlueVirus666",
		// 	descr: "Découvrez le blog de BlueVirus666, dédié aux fans de tatouages et d’art Dark. Inspirations, conseils, coulisses et réflexions artistiques vous y attendent chaque semaine !",
		// 	img: BASE_DOM.concat(defaultImg),
		// };

		// if (aboutPara.title !== undefined) {
		// 	headerPage = {
		// 		title: aboutPara.chron_title_meta,
		// 		descr: aboutPara.chron_descr_meta,
		// 		img: BASE_DOM.concat(aboutPara.img_path) || BASE_DOM.concat(defaultImg),
		// 	};
		// } else {
		// 	headerPage = {
		// 		title: "Blog Tattoo Dark Art – Inspirations & Conseils par BlueVirus666",
		// 		descr: "Découvrez le blog de BlueVirus666, dédié aux fans de tatouages et d’art Dark. Inspirations, conseils, coulisses et réflexions artistiques vous y attendent chaque semaine !",
		// 		title_page: "Le Blog de BlueVirus666 : Inspirations et Conseils Tattoo Dark Art",
		// 		descr_page: "Bienvenue sur le blog de BlueVirus666, un espace pour les passionnés de tatouages et d’art Dark. Plongez dans mes inspirations, suivez mes conseils et découvrez les coulisses de mes créations. Revenez régulièrement pour explorer mon univers artistique.",
		// 		img: BASE_DOM.concat(defaultImg),
		// 	};
		// }

		// let descrAbout = '';
		// let ind = 0;
		// aboutParas.forEach((aboutPara) => {
		// 	if (aboutPara.title_force === 1) {
		// 		ind++;
		// 		if (ind === 1) {
		// 			descrAbout = aboutPara.title;
		// 		} else {
		// 			descrAbout = descrAbout + ' | ' + aboutPara.title;
		// 		}
		// 	}
		// });

		// let headerPage = {
		// 	title: 'En savoir plus à mon propos ? Ou sur mon métier de tatoueuse ?',
		// 	descr: descrAbout,
		// 	img: BASE_DOM.concat(defaultImg),
		// };
		
		return headerPage;
	}

	static getHeaderPageCategory = (sections, sectionId, categories, categoryId, items) => {
		// let cCurrSection = {};
		// let cCurrCategory = {};
		// let cItemsCate = [];
		// let descrCate = '';

		// sections.forEach((section) => {
		// 	if (section.id.toString() === sectionId.toString()) {
		// 		cCurrSection = section;
		// 	}
		// });

		// categories.forEach((category) => {
		// 	if (category.id.toString() === categoryId.toString()) {
		// 		cCurrCategory = category;
		// 	}
		// });

		// items.forEach((item) => {
		// 	if (item.category_id.toString() === categoryId.toString()) {
		// 		cItemsCate.push(item);
		// 	}
		// });

		// switch(cItemsCate.length) {
		// 	case 0:
		// 		descrCate = "Ne ratez pas mes nouveaux postes de tatouages ! N'hésitez pas à me contacter.";
		// 	break;
			
		// 	case 1:
		// 		descrCate = "Aimez-vous mon " + cItemsCate[0].title + " ? D'autres viendront bientôt !"
		// 	break;
			
		// 	case 2:
		// 		descrCate = "Le " + cItemsCate[0].title + " et le " + cItemsCate[1].title + " sauront-ils vous séduire ?"
		// 	break;
			
		// 	case 3:
		// 		descrCate = "Du " + cItemsCate[0].title + " au " + cItemsCate[2].title + " en passant par " + cItemsCate[1].title + " : voici mon art..."
		// 	break;
			
		// 	default:
		// 		const itemLast = cItemsCate.length - 1;
		// 		const itemMiddle = cItemsCate.length / 2;
		// 		descrCate = "Du " + cItemsCate[0].title + " au " + cItemsCate[itemLast].title + " en passant par " + cItemsCate[parseInt(itemMiddle)].title + " : voici mon art..."
		// 	break;
		// }

		// let headerPage = {
		// 	title: cCurrSection.name + ' : ' + cCurrCategory.name,
		// 	descr: descrCate,
		// 	img: BASE_DOM.concat(cCurrCategory.img_path) || BASE_DOM.concat(defaultImg),
		// };
		// return headerPage;

		let cCurrCategory = {};
		categories.forEach((category) => {
			if (category.id.toString() === categoryId.toString()) {
				cCurrCategory = category;
			}
		});

		let headerPage = {
			title: cCurrCategory.categ_title_meta,
			descr: cCurrCategory.categ_descr_meta,
			// img: BASE_DOM.concat(cCurrCategory.img_path) || BASE_DOM.concat(defaultImg),
			img: this.replaceLocalhost(cCurrCategory.img_path) || BASE_DOM.concat(defaultImg),
		};
		return headerPage;
	}



	static getHeaderPageItem = (categories, categoryId, items, itemId) => {
		// let iCurrCategory = {};
		// let iCurrItem = {};

		// categories.forEach((category) => {
		// 	if (category.id.toString() === categoryId.toString()) {
		// 		iCurrCategory = category;
		// 	}
		// });

		// items.forEach((item) => {
		// 	if (item.id.toString() === itemId.toString()) {
		// 		iCurrItem = item;
		// 	}
		// });
		// let available = "DÉJÀ ENCRÉ";
		// if (iCurrItem.available === true) {
		// 	available = "Disponible";
		// }

		// let headerPage = {
		// 	title: iCurrCategory.name + " : " + iCurrItem.title,
		// 	descr: available + " : " + iCurrItem.description,
		// 	img: BASE_DOM.concat(iCurrItem.path) || BASE_DOM.concat(defaultImg),
		// };
		// return headerPage;

		let iCurrItem = {};
		items.forEach((item) => {
			if (item.id.toString() === itemId.toString()) {
				iCurrItem = item;
			}
		});

		let headerPage = {
			title: iCurrItem.item_title_meta,
			descr: iCurrItem.item_descr_meta,
			// img: BASE_DOM.concat(iCurrItem.path) || BASE_DOM.concat(defaultImg),
			img: this.replaceLocalhost(iCurrItem.path) || BASE_DOM.concat(defaultImg),
		};
		return headerPage;		
	}

	static getHeaderPageActu = (actuPara, info) => {
		let headerPage = {};
		if (actuPara !== {} && actuPara.title) {
			headerPage = {
				title: this.replaceAddr(actuPara.actu_title_meta, info.shop_address_city, info.shop_address_city_near),
				// title: actuPara.actu_title_meta,
				// descr: actuPara.actu_descr_meta,
				descr: this.replaceAddr(actuPara.actu_descr_meta, info.shop_address_city, info.shop_address_city_near),
				// img: BASE_DOM.concat(actuPara.path) || BASE_DOM.concat(defaultImg),
				img: this.replaceLocalhost(actuPara.path) || BASE_DOM.concat(defaultImg),
			};
		} else {
			headerPage = {
				title: "BlueVirus666 - Prochains événements en préparation - Ne manquez rien !",
				descr: "Nos prochains événements sont en cours de préparation. Revenez bientôt pour découvrir toutes les nouveautés et soyez les premiers informés des prochaines dates importantes !",
				img: BASE_DOM.concat(defaultImg),
			};
		}
		return headerPage;
	}

	static getHeaderPagePlan = () => {
		let headerPage = {
			title: "BlueVirus666 - Plan du site",
            descr: "Explorez l’univers unique de BlueVirus666, tatoueuse spécialisée dans le Dark Art. Découvrez mes tatouages personnalisés ainsi que mes créations sur vêtements et coques de téléphone.",
            img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

}
