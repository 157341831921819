
// import { useNavigate } from 'react-router-dom';

// import { ErrorNotFound_ctrl } from '../../../controler/';

import {
	StyENFPlaceDiv,
	StyENFTitleH1,
	StyENFTextP,
	StyENFLinkA,
	StyENFImgImg,
} from '../../../style/';

import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

import imgENF from '../../../img/tools/imgENF.jpg'

function ErrorNotFound() {

	// const navigate = useNavigate();

	// const forCtrler = {
	// 	navigate: navigate,
	// };
	// const ctrler = ErrorNotFound_ctrl(forCtrler);

	const headerPage = Getter.getHeaderPage({pageName: 'NOTFOUND'});

	return (
		<div>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />

		    <StyENFPlaceDiv>
		        <StyENFTitleH1>Oups... Cette page s’est évanouie avant de se faire tatouer !</StyENFTitleH1>
	            <StyENFImgImg src={imgENF} alt="Oups... Cette page s’est évanouie avant de se faire tatouer !" />
	            <br/>
		        <StyENFTextP>
		            Il semblerait que cette page ait pris peur avant même de passer sous l’aiguille... Mais rassurez-vous, mon art, lui, est toujours là !
		        </StyENFTextP>
	            <br/>
		        <div>
		            <StyENFLinkA href="/creations">Voir ma galerie</StyENFLinkA>
		            <br/>
		            <br/>
		            <StyENFLinkA href="/chronique/default">Découvrir mon blog</StyENFLinkA>
		            <br/>
		            <br/>
		            <StyENFLinkA href="/contact">Me contacter</StyENFLinkA>
		            <br/>
		            <br/>
		        </div>
		    </StyENFPlaceDiv>
		</div>
	);
} 

export default ErrorNotFound;



			// Sorry this page doesn´t exist. Click <span onClick={ctrler.handleClickOnWelcome}>here to come back into Blue Virus website</span> !
