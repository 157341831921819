
// import { useSelector } from 'react-redux';

// import {
// 	globalStore,
// 	getAboutParagraphs,
// } from '../../../globalstate';

import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

function Siteplan({elementsTree, aboutAll}) {

	const headerPage = Getter.getHeaderPage({pageName: 'SITEPLAN'});

	return (
		<article>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<header>
				<h1>Plan du site</h1>
			</header>
			<main key="main_siteplan">
				<nav>
					<a href="/"><h2>Accueil</h2></a>
					<hr/>
					<a href="/creations"><h2>Accueil</h2></a>
					<hr/>
					<a href="/evenement"><h2>Événement</h2></a>
					<hr/>
					<h2>Chroniques</h2>
					{
						aboutAll.length !== 0 && aboutAll.map((about) => {
							let aboutLink = "/chronique/" + about.slug;
							return (
								<div key={`about${about.id}`}>
									<a href={aboutLink}><h3>{about.title}</h3></a>
								</div>
							);
						})
					}
					<hr/>
					<a href="/contact"><h2>À propos / Contact</h2></a>
					<hr/>
					<h2>Réalisations</h2>
					{
						elementsTree.sections.map((section) => {
							return (
								<div key={`section${section.id}`}>
									<h3>{section.name}</h3>
									{
										elementsTree.categories.map((category) => {
											if (category.section_id === section.id) {
												let categoryLink = "/categorie/" + category.slug;
												return (
													<div key={`category${category.id}`}>
														<a href={categoryLink}><h4>{category.name}</h4></a>
														{
															elementsTree.items.map((item) => {
																if (item.category_id === category.id) {
																	let itemLink = "/realisation/" + item.slug;
																	return (
																		<div key={`item${item.id}`}>
																			<a href={itemLink}><h5>{item.title}</h5></a>
																		</div>
																	);
																} else { return (<div></div>); }
															})
														}
													</div>
												);
											} else { return (<div></div>); }
										})
									}
								</div>
							);
						})
					}
				</nav>
			</main>
		</article>
	);
};

export default Siteplan;

