
import styled from 'styled-components';


	
const StyENFPlaceDiv = styled.div`
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
`
	
const StyENFTitleH1 = styled.h1`
	font-size: 35px;
	font-weight: 900;
`
	
const StyENFTextP = styled.p`
`

const StyENFLinkA = styled.a`
	text-decoration: underline;
	color: rgb(215, 216, 221);
	font-size: 17px;
`
	
const StyENFImgImg = styled.img`
	border: 1px solid rgb(215, 216, 221);
	border-radius: 10px 10px 10px 10px;
	width: 90%;
	max-width: 700px;
`

export {
	StyENFPlaceDiv,
	StyENFTitleH1,
	StyENFTextP,
	StyENFLinkA,
	StyENFImgImg,
};