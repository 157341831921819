
import styled from 'styled-components';


const StyActuPageDiv = styled.article`
	text-align: center;
`

const StyActuImgImg = styled.img`
	border: 1px solid rgb(215, 216, 221);
	border-radius: 10px 10px 10px 10px;
	width: 90%;
	max-width: 700px;
`

const StyActuDateP = styled.p`
	font-weight: 900;
	font-size: 20;
`

const StyActuRdvP = styled.p`
	font-weight: 900;
	font-size: 20;
	color: rgb(0, 158, 199);
`

const StyActuTextP = styled.p`
	font-weight: 400;
	font-size: 14;
`

export {
	StyActuPageDiv,
	StyActuImgImg,
	StyActuDateP,
	StyActuRdvP,
	StyActuTextP,
};