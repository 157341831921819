
import { useSelector } from 'react-redux';

import { Getter } from '../../../../../generaltools';

import {
	globalStore,
	getFAdmCat_na,
	getFAdmCat_or,
	getFAdmCat_ip,
	getFAdmCat_ai,
	getFAdmCat_si,
	getFAdmCat_en,
	getFAdmCat_de,
	// setFAdmCat_or,
	getFAdmCat_tm,
	getFAdmCat_tp,
	getFAdmCat_dm,
	getFAdmCat_dp,
} from '../../../../../globalstate';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminPartInputImg,
	StyAdminSaveInputButton,
	StyAdminPartInputCheckboxInput,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
	StyAdminPartInputListSelect,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
	StyAdminPartInputTextareaLabel,
	StyAdminPartInputTextarea,
} from '../../../../../style/';

function AdminManageCategory({onCLickSave, ctrler, sectionList}) {

	const category_na = useSelector(getFAdmCat_na(globalStore.getState())) || "";
	const category_or = useSelector(getFAdmCat_or(globalStore.getState())) || "";
	const category_ip = useSelector(getFAdmCat_ip(globalStore.getState())) || "";
	const category_ai = useSelector(getFAdmCat_ai(globalStore.getState()));
	const category_si = useSelector(getFAdmCat_si(globalStore.getState())) || 0;
	const category_en = useSelector(getFAdmCat_en(globalStore.getState()));
	const category_de = useSelector(getFAdmCat_de(globalStore.getState()));
	const category_tm = useSelector(getFAdmCat_tm(globalStore.getState())) || "";
	const category_tp = useSelector(getFAdmCat_tp(globalStore.getState())) || "";
	const category_dm = useSelector(getFAdmCat_dm(globalStore.getState())) || "";
	const category_dp = useSelector(getFAdmCat_dp(globalStore.getState())) || "";

	// if (category_or === 0) {
	// 	globalStore.dispatch(setFAdmCat_or(ctrler.maxOrdreP1));
	// }

	const categoryOrders = ctrler.putCategoryOrder();

	return (
		<div>
			<StyAdminPartTittleH4>Modifier la catégorie</StyAdminPartTittleH4>
			<form>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='section_id_category'>Section :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_si ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='section_id_category' name='section_id_category' value={category_si} onChange={ctrler.handleChangeCategory_si}>
						{
							sectionList.map((sectionI, liId) => {
								liId++;
								return (<option key={liId} value={sectionI.id}>{sectionI.name}</option>);
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='name_category'>Nom :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_na ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='name_category' name='name_category' value={category_na} onChange={ctrler.handleChangeCategory_na} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='order_category'>Ordre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_or ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='order_category' name='order_category' value={category_or} onChange={ctrler.handleChangeCategory_or}>
						{
							categoryOrders.map((categoryOrder, lkId) => {
								lkId++;
								return (<option value={categoryOrder} key={lkId}>{categoryOrder}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='tm_category'>Titre meta :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_na ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='tm_category' name='tm_category' value={category_tm} onChange={ctrler.handleChangeCategory_tm} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='tp_category'>Titre page :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_na ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='tp_category' name='tp_category' value={category_tp} onChange={ctrler.handleChangeCategory_tp} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputTextareaLabel htmlFor='dm_category'>Description meta :</StyAdminPartInputTextareaLabel>
					<StyAdminPartInputTextarea id='dm_category' name='dm_category' value={category_dm} onChange={ctrler.handleChangeCategory_dm}>
					</StyAdminPartInputTextarea>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputTextareaLabel htmlFor='dp_category'>Description page :</StyAdminPartInputTextareaLabel>
					<StyAdminPartInputTextarea id='dp_category' name='dp_category' value={category_dp} onChange={ctrler.handleChangeCategory_dp}>
					</StyAdminPartInputTextarea>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Image automatique :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='auto_img_y_category'>Oui</StyAdminPartInputRadioLabel>
						{ category_ai === true ? (
								<StyAdminPartInputRadioInput type='radio' id='auto_img_y_category' name='auto_img_category' value='Y' defaultChecked onClick={ctrler.handleChangeCategory_ai} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='auto_img_y_category' name='auto_img_category' value='Y' onClick={ctrler.handleChangeCategory_ai} />
							)
						}
					<StyAdminPartInputRadioLabel htmlFor='auto_img_n_category'>Non</StyAdminPartInputRadioLabel>
						{ category_ai === true ? (
								<StyAdminPartInputRadioInput type='radio' id='auto_img_n_category' name='auto_img_category' value='N' onClick={ctrler.handleChangeCategory_ai} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='auto_img_n_category' name='auto_img_category' value='N' defaultChecked onClick={ctrler.handleChangeCategory_ai} />
							)
						}
				</StyAdminPartInputDiv>

				{
					ctrler.catDisplay_ip === true ? (
						<StyAdminPartInputDiv>
							<StyAdminPartInputLabel htmlFor='img_path_category'>Image :</StyAdminPartInputLabel>
							<StyAdminPartInputInput type='file' id='img_path_category' name='img_path_category' onChange={ctrler.handleChangeCategory_ip} />
							<StyAdminPartInputImg src={category_ip.service_url || Getter.getImgPath(category_ip)} alt='imgUploaded' />
						</StyAdminPartInputDiv>
					) : (<div></div>)
				}

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Activer :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='enable_y_category'>Oui</StyAdminPartInputRadioLabel>
						{ category_en === true ? (
								<StyAdminPartInputRadioInput type='radio' id='enable_y_category' name='enable_category' value='Y' defaultChecked onClick={ctrler.handleChangeCategory_en} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='enable_y_category' name='enable_category' value='Y' onClick={ctrler.handleChangeCategory_en} />
							)
						}
					<StyAdminPartInputRadioLabel htmlFor='enable_n_category'>Non</StyAdminPartInputRadioLabel>
						{ category_en === true ? (
								<StyAdminPartInputRadioInput type='radio' id='enable_n_category' name='enable_category' value='N' onClick={ctrler.handleChangeCategory_en} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='enable_n_category' name='enable_category' value='N' defaultChecked onClick={ctrler.handleChangeCategory_en} />
							)
						}
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='delete_category'>Supprimer :</StyAdminPartInputLabel>
					<StyAdminPartInputCheckboxInput type='checkbox' id='delete_category' name='delete_category' value={category_de} onChange={ctrler.handleChangeCategory_de} />
				</StyAdminPartInputDiv>

				<StyAdminPartErrorDiv>
					{ctrler.errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={onCLickSave}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminManageCategory;